<template>
    <div>
        <component :is="targetComponent()" :brandConfig="brandConfig" :country="country" :storeId="storeId"
            :storeName="storeName" :txNo="txNo"/>
    </div>
</template>

<script>
export default {
    components: {
        MY: () => import("./MY/Registration.vue"),
        TH: () => import("./TH/Registration.vue"),
        PH: () => import("./PH/Registration.vue"),
        frontierMY: () => import("./MY/FrontierRegistration.vue"),
        frontierTH: () => import("./TH/FrontierRegistration.vue"),
        frontierPH: () => import("./PH/FrontierRegistration.vue"),
        frontierVN: () => import("./VN/FrontierRegistration.vue"),
    },
    props: {
        brandConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
        country: {
            type: String,
            default: "",
        },
        storeId: {
            type: String,
            default: "",
        },
        storeName: {
            type: String,
            default: "",
        },
        txNo: {
            type: String,
            default: "",
        },
    },
    created() {
    },
    data() {
        return {
            componentMapping: {
                MY: "MY",
                TH: "TH",
                PH: "PH"
            },
            frontierComponentMapping: {
                MY: "frontierMY",
                TH: "frontierTH",
                PH: "frontierPH",
                VN: "frontierVN",
            },
            frontierCountryBrandMapping: {
                MY: ["lelabo", "clinique", "companycorporatesales", "origins", "lamer", "tomford", "esteelauder", "mac","bobbibrown","jomalone"],
                TH: ["tomford","lelabo","companycorporatesales","jomalone", "mac","bobbibrown", "esteelauder", "clinique", "lamer", "origins", "aveda"],
                PH: ["lamer", "esteelauder", "mac","bobbibrown","clinique","multibrand","jomalone","origins","lelabo","tomford"],
                VN: ["lamer", "esteelauder", "mac","bobbibrown","clinique","multibrand","jomalone","origins","lelabo","tomford"],
            }
        };
    },
    watch: {},
    methods: {
        targetComponent() {
            if (this.frontierCountryBrandMapping[this.country].includes(this.brandConfig.name)) {
                return this.frontierComponentMapping[this.country]
            }
            return this.componentMapping[this.country]
        }
    }
}
</script>

<style scoped></style>
